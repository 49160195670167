<template>
   <BT-Blades :maximizedCount="10" @loaded="val => { bladesData = val }">
        <template v-slot="bladesData">
            <v-navigation-drawer
                app
                :expand-on-hover="!$vuetify.breakpoint.mobile && !mPermanent"
                style="height: 100%;"
                :permanent="mPermanent">
                <v-list class="ma-0 pa-0">
                    <v-list-item class="px-2" dense>
                        <v-list-item-avatar rounded class="mx-auto" size="36">
                            <v-img :src="companyLogoURL(customer ? customer.buyer.id : null)">
                                <template v-slot:placeholder><v-icon>mdi-account-outline</v-icon></template>
                            </v-img>
                        </v-list-item-avatar>
                        <v-list-item-action>
                            <v-row class="mr-1">
                                <v-btn
                                    :to="{ name: 'office-portal-home' }"
                                    icon
                                    small>
                                    <v-icon small>mdi-home</v-icon>
                                </v-btn>
                                <v-btn
                                    @click="$vuetify.theme.dark = !$vuetify.theme.dark"
                                    icon
                                    small
                                    title="Toggle Light/Dark Theme">
                                    <v-icon small>mdi-theme-light-dark</v-icon>
                                </v-btn>
                                <v-btn
                                    @click="mPermanent = !mPermanent"
                                    icon
                                    small
                                    title="Pin/Unpin Menu">
                                    <v-icon small>{{ mPermanent ? 'mdi-pin-off' : 'mdi-pin' }}</v-icon>
                                </v-btn>
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item class="v-step-sales-customer" @click="findCustomer">
                        <v-list-item-icon>
                            <v-icon>mdi-filter</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-subtitle class="subtitle-1">
                                <span v-if="customer != null">{{ customer.buyer.companyName }}</span>
                                <span v-else>All Customers</span>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn
                                v-if="customer != null" 
                                @click.stop="unselectCustomer(bladesData)"
                                small
                                icon>
                                <v-icon small>mdi-close</v-icon>
                            </v-btn>
                            <v-icon v-else>mdi-all-inclusive</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider class="my-2" />
                    <v-list-item class="v-step-sales-orders" @click="open(bladesData, { bladeName: 'customer-orders' })" dense>
                        <v-list-item-icon>
                            <v-icon>{{ $BlitzIt.navigation.findIcon('customer-orders') }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Orders</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="v-step-sales-standing-orders" @click="open(bladesData, { bladeName: 'standing-customer-orders' })" dense>
                        <v-list-item-icon>
                            <v-icon>{{ $BlitzIt.navigation.findIcon('standing-customer-orders') }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Standing Orders</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="v-step-sales-invoices" @click="open(bladesData, { bladeName: 'customer-invoices' })" dense>
                        <v-list-item-icon>
                            <v-icon>{{ $BlitzIt.navigation.findIcon('customer-invoices') }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Invoices</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="v-step-sales-consignments" @click="open(bladesData, { bladeName: 'stock-consignments' })" dense>
                        <v-list-item-icon>
                            <v-icon>{{ $BlitzIt.navigation.findIcon('stock-consignments') }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Consignments</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="v-step-sales-movements" @click="open(bladesData, { bladeName: 'courier-movements' })" dense>
                        <v-list-item-icon>
                            <v-icon>{{ $BlitzIt.navigation.findIcon('courier-movements') }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Movements</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-navigation-drawer>

            <!-- <BT-Select-Dialog
                @change="v => { selectCustomer(null, v != null ? v : null, bladesData) }"
                :canCancel="false"
                canSearch
                v-model="customer"
                hideButton
                label="Customer"
                listItemText="buyer.companyName"
                listItemValue="id"
                height="400"
                navigation="customers"
                :params="{ properties: 'ID,Buyer' }"
                :persistent="false"
                returnObject
                :showToggle="changeCustomerToggle"
                width="550" /> -->

            <Customer-Orders-Blade :bladesData="bladesData" />
            <Customer-Order-Blade :bladesData="bladesData" />
            <Standing-Customer-Orders-Blade :bladesData="bladesData" />
            <Standing-Customer-Order-Blade :bladesData="bladesData" />
            <Customer-Invoices-Blade :bladesData="bladesData" />
            <Customer-Invoice-Blade :bladesData="bladesData" />
            <Stock-Consignments-Blade :bladesData="bladesData" />
            <Stock-Consignment-Blade :bladesData="bladesData" />
            <!-- <Customer-Consignments-Blade :bladesData="bladesData" /> -->
            <Customer-Consignment-Blade :bladesData="bladesData" />
            <!-- <Courier-Orders-Blade :bladesData="bladesData" /> -->
            <Courier-Order-Blade :bladesData="bladesData" />
            <Movements-Blade :bladesData="bladesData" />
            <Movement-Blade :bladesData="bladesData" />
            <!-- <Journeys-Blade :bladesData="bladesData" /> -->
            <Journey-Blade :bladesData="bladesData" />

        </template>
    </BT-Blades>
</template>

<script>
export default {
    name: 'Sales-Hub',
    components: {
        // BTSelectDialog: () => import('~components/BT-Select-Dialog.vue'),
        CustomerInvoicesBlade: () => import('~home/customer-invoices/Customer-Invoices-Blade.vue'),
        CustomerInvoiceBlade: () => import('~home/customer-invoices/Customer-Invoice-Blade.vue'),
        CustomerOrdersBlade: () => import('~home/customer-orders/Customer-Orders-Blade.vue'),
        CustomerOrderBlade: () => import('~home/customer-orders/Customer-Order-Blade.vue'),
        StandingCustomerOrdersBlade: () => import('~home/standing-orders/Standing-Customer-Orders-Blade.vue'),
        StandingCustomerOrderBlade: () => import('~home/standing-orders/Standing-Customer-Order-Blade.vue'),
        StockConsignmentsBlade: () => import('~home/stock-consignments/Stock-Consignments-Blade.vue'),
        StockConsignmentBlade: () => import('~home/stock-consignments/Stock-Consignment-Blade.vue'),
        // CustomerConsignmentsBlade: () => import('~home/customer-consignments/Customer-Consignments-Blade.vue'),
        CustomerConsignmentBlade: () => import('~home/customer-consignments/Customer-Consignment-Blade.vue'),
        // CourierOrdersBlade: () => import('~home/courier-orders/Courier-Orders-Blade.vue'),
        CourierOrderBlade: () => import('~home/courier-orders/Courier-Order-Blade.vue'),
        MovementsBlade: () => import('~home/movements/Movements-Blade.vue'),
        MovementBlade: () => import('~home/movements/Movement-Blade.vue'),
        JourneyBlade: () => import('~home/journeys/Journey-Blade.vue'),
        // JourneysBlade: () => import('~home/journeys/Journeys-Blade.vue'),
    },
    data: function() {
        return {
            bladesData: {
                type: Object,
                default: null
            },
            bladesToClear: [
                { bladeName: 'customer-orders' },
                { bladeName: 'customer-order' },
                { bladeName: 'standing-customer-orders' },
                { bladeName: 'standing-customer-order' },
                { bladeName: 'customer-invoices' },
                { bladeName: 'customer-invoice' },
                { bladeName: 'stock-consignments' },
                { bladeName: 'stock-consignment' },
                { bladeName: 'courier-movements' },
                { bladeName: 'courier-movement' }],
            customer: null,
            // changeCustomerToggle: false,
            mPermanent: false,
            shift: false,
            alt: false,
        }
    },
    mounted() {
        window.addEventListener('keyup', this.keyChangeCustomer);
        window.addEventListener('keydown', this.keyDownChange);
    },
    unmounted() {
        window.removeEventListener('keyup', this.keyChangeCustomer);
        window.removeEventListener('keydown', this.keyDownChange);
    },
    methods: {
        keyChangeCustomer(ev) {
            if (ev.keyCode == 18) {
                this.alt = false;
            }
            else if (ev.keyCode == 16) {
                this.shift = false;
            }
            else if (ev.keyCode == 192) {
                if (this.alt) {
                    this.unselectCustomer(this.bladesData);
                }
                else {
                    this.findCustomer(this.bladesData);
                    // this.changeCustomerToggle = !this.changeCustomerToggle;
                }
            }
            else if (ev.keyCode == 49) {
                if (this.alt) {
                    this.bladesData.openBlade({ bladeName: 'customer-order', bladesToClear: this.bladesToClear, data: { id: 'new', agreementID: this.customer != null ? this.customer.id : null } });
                }
                else if (this.shift) {
                    this.open(this.bladesData, { bladeName: 'customer-orders', bladesToClear: this.bladesToClear });
                }
            }
            else if (ev.keyCode == 50) {
                if (this.alt) {
                    this.bladesData.openBlade({ bladeName: 'standing-customer-order', bladesToClear: this.bladesToClear, data: { id: 'new', agreementID: this.customer != null ? this.customer.id : null } });
                }
                else if (this.shift) {
                    this.open(this.bladesData, { bladeName: 'standing-customer-orders', bladesToClear: this.bladesToClear });
                }
            }
            else if (ev.keyCode == 51) {
                if (this.shift) {
                    this.open(this.bladesData, { bladeName: 'customer-invoices', bladesToClear: this.bladesToClear });
                }
            }
            else if (ev.keyCode == 52) {
                if (this.shift) {
                    this.open(this.bladesData, { bladeName: 'stock-consignments', bladesToClear: this.bladesToClear });
                }
            }
            else if (ev.keyCode == 53) {
                if (this.shift) {
                    this.open(this.bladesData, { bladeName: 'courier-movements', bladesToClear: this.bladesToClear });
                }
            }
        },
        keyDownChange(ev) {
            if (ev.keyCode == 18) {
                this.alt = true;
            }
            else if (ev.keyCode == 16) {
                this.shift = true;
            }

            console.log(ev.keyCode);
        },
        open(bladesData, blade) {
            if (blade.to != null) {
                this.$router.push(blade.to);
            }
            else {
                blade.data = { 
                    customerID: this.customer != null ? this.customer.buyer.id : null,
                    agreementID: this.customer != null ? this.customer.id : null
                };

                bladesData.openBlade({
                    bladeName: blade.bladeName,
                    bladesToClear: this.bladesToClear,
                    data: blade.data
                });
            }
        },
        async findCustomer(bladesData) {
            var customer = await this.$selectItem({
                navigation: 'customers',
                itemText: 'buyer.companyName',
                params: { properties: 'ID,Buyer' }
            });
            
            if (customer != null) {
                this.customer = customer;
                bladesData.closeBlade({ bladeName: 'customer-order' });
                bladesData.closeBlade({ bladeName: 'standing-customer-order' });
                bladesData.closeBlade({ bladeName: 'customer-invoice' });
                bladesData.closeBlade({ bladeName: 'stock-consignment' });
                bladesData.closeBlade({ bladeName: 'courier-movement' });

                bladesData.updateBladeData({ bladeName: 'customer-orders' }, { customerID: customer.buyer.id, agreementID: customer.id }); //, id: customer.id, customer: customer });
                bladesData.updateBladeData({ bladeName: 'standing-customer-orders' }, { customerID: customer.buyer.id, agreementID: customer.id }); //, id: customer.id, customer: customer });
                bladesData.updateBladeData({ bladeName: 'customer-invoices' }, { customerID: customer.buyer.id });
                bladesData.updateBladeData({ bladeName: 'stock-consignments' }, { customerID: customer.buyer.id }); //, id: customer.id, agreementID: customer.id, customer: customer });
                bladesData.updateBladeData({ bladeName: 'courier-movements' }, { customerID: customer.buyer.id });
            }

        },
        // selectCustomer(bladeData, customer, bladesData) {
        //     if (customer != null) {
        //         bladesData.closeBlade({ bladeName: 'customer-order' });
        //         bladesData.closeBlade({ bladeName: 'standing-customer-order' });
        //         bladesData.closeBlade({ bladeName: 'customer-invoice' });
        //         bladesData.closeBlade({ bladeName: 'stock-consignment' });
        //         bladesData.closeBlade({ bladeName: 'courier-movement' });

        //         bladesData.updateBladeData({ bladeName: 'customer-orders' }, { customerID: customer.buyer.id, agreementID: customer.id }); //, id: customer.id, customer: customer });
        //         bladesData.updateBladeData({ bladeName: 'standing-customer-orders' }, { customerID: customer.buyer.id, agreementID: customer.id }); //, id: customer.id, customer: customer });
        //         bladesData.updateBladeData({ bladeName: 'customer-invoices' }, { customerID: customer.buyer.id });
        //         bladesData.updateBladeData({ bladeName: 'stock-consignments' }, { customerID: customer.buyer.id }); //, id: customer.id, agreementID: customer.id, customer: customer });
        //         bladesData.updateBladeData({ bladeName: 'courier-movements' }, { customerID: customer.buyer.id });
        //     }
        // },
        unselectCustomer(bladesData) {
            if (bladesData == null) {
                return;
            }

            this.customer = null;

            bladesData.updateBladeData({ bladeName: 'customer-orders' }, { customerID: null, agreementID: null }); //, id: customer.id, customer: customer });
            bladesData.updateBladeData({ bladeName: 'standing-customer-orders' }, { customerID: null, agreementID: null }); //, id: customer.id, customer: customer });
            bladesData.updateBladeData({ bladeName: 'customer-invoices' }, { customerID: null });
            bladesData.updateBladeData({ bladeName: 'stock-consignments' }, { customerID: null }); //, id: customer.id, agreementID: customer.id, customer: customer });
            bladesData.updateBladeData({ bladeName: 'courier-movements' }, { customerID: null });
        }
    }
}
</script>